<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>商品计划统计</span>
            </div>

            <!-- 查看采购计划汇总-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToMerchandisingSummary"
                            class="font1"
                            :disabled="!hasOpenMerchandisingSummaryPrivilege"
                        >
                            查看采购计划汇总
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <el-tooltip
                            class="font2"
                            style="text-decoration: underline"
                            :content="roleNamesForOpenMerchandisingSummary"
                            placement="top"
                        >
                            <span>此类角色</span>
                        </el-tooltip>
                        可查看商品计划汇总
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToMerchandisingSummary"
                            :disabled="!hasOpenMerchandisingSummaryPrivilege"
                            >①查看商品计划汇总</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>选择机构-点击查询（如需要缩小搜索范围可以选择商品计划/日期等）</span>
                    </el-col>
                </el-row>
            </el-card>

            <!--查看商品计划明细-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToMerchandisingFlow"
                            class="font1"
                            :disabled="!hasOpenMerchandisingFlowPrivilege"
                            >查看商品计划明细</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForOpenMerchandisingFlow"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可查看商品计划明细</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToMerchandisingFlow"
                            :disabled="!hasOpenMerchandisingFlowPrivilege"
                            >①查看商品计划明细</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>选择机构-点击查询（如需要缩小搜索范围可以选择商品计划/消费会员/日期等）</span>
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';

const merchandisingSummaryPrivilegeFlag = 'menu.report.merchandising.summary';
const openMerchandisingSummaryPrivilegeFlag = `${merchandisingSummaryPrivilegeFlag}.open`;

const merchandisingFlowPrivilegeFlag = 'menu.report.merchandising.flow';
const openMerchandisingFlowPrivilegeFlag = `${merchandisingFlowPrivilegeFlag}.open`;

export default {
    name: 'MerchandisingReportGuide',
    data() {
        return {
            rolesForOpenMerchandisingSummary: [],
            rolesForOpenMerchandisingFlow: [],
        };
    },
    created() {
        //查询“能够查询商品计划汇总”的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(openMerchandisingSummaryPrivilegeFlag).then((rst) => {
            this.rolesForOpenMerchandisingSummary = rst;
        });
        //查询“能够查询商品计划流水”的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(openMerchandisingFlowPrivilegeFlag).then((rst) => {
            this.rolesForOpenMerchandisingFlow = rst;
        });
    },
    computed: {
        roleNamesForOpenMerchandisingSummary() {
            return this.rolesForOpenMerchandisingSummary.map((e) => e.name).join('，');
        },
        roleNamesForOpenMerchandisingFlow() {
            return this.rolesForOpenMerchandisingFlow.map((e) => e.name).join('，');
        },
    },

    methods: {
        jumpToMerchandisingSummary() {
            Util.nameJump(this, merchandisingSummaryPrivilegeFlag);
        },
        jumpToMerchandisingFlow() {
            Util.nameJump(this, merchandisingFlowPrivilegeFlag);
        },

        hasOpenMerchandisingSummaryPrivilege() {
            return this.hasPrivilege(openMerchandisingSummaryPrivilegeFlag);
        },
        hasOpenMerchandisingFlowPrivilege() {
            return this.hasPrivilege(openMerchandisingFlowPrivilegeFlag);
        },
    },
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
